<template>
  <div>
    <div class="mb-3">
      <label for="">Tiempo</label>
      <input
        type="text"
        disabled
        class="form-control"
        :value="ini_time | dateTimeFormat"
      />
    </div>
    <div class="form-group">
      <label for="">Duracion</label>
      <select-duration
        v-model="duration"
        :interval="interval"
        :employeeId="employee_id"
        :iniTime="ini_time"
        ref="selDur"
      ></select-duration>
      <app-small-form-errors :errors="errors.duration"></app-small-form-errors>
    </div>

    <div class="form-group">
      <app-switch v-model="newClient"> Nuevo paciente </app-switch>
      <!-- <app-checkbox >Nuevo Paciente</app-checkbox> -->
    </div>

    <div v-show="newClient">
      <!-- <ClientMiniForm ref="elClientForm"></ClientMiniForm> -->
      <ClientForm ref="elClientForm" :disabledSubmit="true"></ClientForm>
    </div>

    <div class="form-group" v-show="!newClient">
      <label for="">Paciente</label>
      <SelectClient ref="selCli" v-model="client_id"></SelectClient>
      <app-small-form-errors :errors="errors.client_id"></app-small-form-errors>
    </div>
    <!-- <div class="form-group">
      <label for="">Tratamiento</label>
      <SelectTreatment
        :client_id="client_id"
        v-model="treatment_plan_id"
      ></SelectTreatment>
    </div> -->
    <!-- <div class="form-group">
      <label for="">Producto</label>
      <SelectProduct v-model="product_id"></SelectProduct>
    </div> -->

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link" :class="{ active: quote_mode == 'C' }"
          @click="quote_mode = 'C'">
          Comentario
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link" :class="{ active: quote_mode == 'T' }"
          @click="quote_mode = 'T'">
          Tratamiento
        </a>
      </li>
    </ul>
    <QuoteFormTreatmentsTable
      v-show="quote_mode == 'T'" ref="elQuoteFormTreatmentsTable"
      :key="ini_time" />
    <div class="form-group" v-show="quote_mode == 'C'">
      <label for="">Comentario</label>
      <app-textarea v-model="commentary"></app-textarea>
      <app-small-form-errors
        :errors="errors.commentary"
      ></app-small-form-errors>
    </div>
    <app-button-submit :disabled="errors" @click="save()"></app-button-submit>
  </div>
</template>
<script>
import SelectClient from "../../store-module/clients/Select";
// import SelectTreatment from "../treatments/Select";
import SelectDuration from "./select-duration";
import { myMoment, momentDateTimeFormat } from "../../utils/myMoment";
import { DentalClinicService } from "../service";
// import SelectProduct from "../../store-module/products/Select";
import { pieceName } from "../odontogram/tooth-utils";
import ClientForm from "../../store-module/clients/Form.vue";
// import ClientMiniForm from "./ClientMiniForm";
import { StoreService } from "../../store-module/service";
import validate from "validate.js";
import QuoteFormTreatmentsTable from "./QuoteFormTreatmentsTable.vue";

const formRules = function(data) {
  var rules = {
    duration: {
      presence: { allowEmpty: false, message: "Requerido" },
    },
  };
  // commentary: {
  //   presence: { allowEmpty: false, message: "Requerido" }
  // }
  if (!data.newClient) {
    rules["client_id"] = {
      presence: { allowEmpty: false, message: "Requerido" },
    };
  }
  return rules;
};

export default {
  components: {
    QuoteFormTreatmentsTable,
    SelectClient,
    // SelectTreatment,
    SelectDuration,
    // SelectProduct,
    ClientForm,
    // ClientMiniForm
  },

  // directives
  // filters

  props: {
    employee_id: {},
    // ini_time: {},
    interval: {},
    group: {
      default: 1,
    }, 
  },

  data: () => ({ 
    ini_time: null,
    client_id: null,
    treatment_plan_id: null,
    commentary: null,
    duration: null,
    end_time: null,
    product_id: null,
    newClient: false,
    quote_mode: "C",
  }),

  computed: {
    errors() {
      var result = validate(this.$data, formRules(this.$data), {
        fullMessages: false,
      });
      return result || false;
    },
  },

  watch: {
    group(to) {
      this.$refs.selDur.setGroup(to);
    },
  },

  mounted() {
    //
  },

  methods: {
    reset() {
      this.client_id = null;
      this.duration = null;
      this.$refs.selCli.reset();
      this.commentary = null;
      this.newClient = false;
      this.$refs.elClientForm.reset();
      this.$refs.elQuoteFormTreatmentsTable.reset();
    },
    setIniTime(ini_time, client_id, commentary) {
      this.reset();
      this.ini_time = myMoment(ini_time).format(momentDateTimeFormat);
      if (client_id) {
        this.$refs.selCli.setValueFromId(client_id);
      }
      if (commentary) {
        this.commentary = commentary;
      }

      if (this.$route.query.client_id) {
        this.$refs.selCli.setValueFromId(this.$route.query.client_id);
      }

      if (this.$route.query.quote_id) {
        DentalClinicService.getQuote(this.$route.query.quote_id).then((res) => {
          this.commentary = res.commentary;
        });
      }

      if (this.$route.query.treatment_details) {
        DentalClinicService.getTreatmentPlanDetails({
          treatment_details: this.$route.query.treatment_details
            .toString()
            .split(","),
        }).then((list) => {
          let oDicc = {};

          list.map((x) => {
            if (!oDicc[x.product_name])
              oDicc[x.product_name] = `${pieceName(x.piece)}`;
            else oDicc[x.product_name] += `, ${pieceName(x.piece)}`;
          });

          this.commentary = "";
          for (let k in oDicc) {
            this.commentary += `${k} en ${oDicc[k]}\n`;
          }

          // this.commentary = list.reduce((a, c) => {
          //   return `${a}${c.product_name} en ${pieceName(c.piece)}\n`;
          // }, "");
        });
      }
    },
    async save() {
      if (this.newClient) {
        var result = await StoreService.saveClient({
          ...this.$refs.elClientForm.getData(),
        });
        this.client_id = result.id;
      }

      var end_time = myMoment(this.ini_time, momentDateTimeFormat);
      end_time.add(this.duration, "minute");
      let treatments = this.$refs.elQuoteFormTreatmentsTable.getValues();
      DentalClinicService.saveQuote({
        // treatment_plan_id: this.treatment_plan_id,
        client_id: this.client_id,
        employee_id: this.employee_id,
        ini_date: this.ini_time,
        end_date: end_time.format(momentDateTimeFormat),
        product_id: this.product_id,
        commentary: this.commentary,
        treatments: this.quote_mode == "T" ? treatments : [],
        duration: this.duration,
        intervals: this.duration / this.interval,
        group: this.group,
        treatment_details: this.$route.query.treatment_details,
        quote_id: this.$route.query.quote_id,
      }).then(
        (res) => {
          this.$emit("submitted", res);
        },
        (err) => {
          this.$store.dispatch("showError", err.response.data.message);
        }
      );
    },
  },
};
</script>

<style></style>
